import React from 'react';
import Img from 'gatsby-image';
import { graphql, StaticQuery } from 'gatsby';
import { altTags } from '../../const/metaData';

type ImageProps = {
  imagePath: string;
  style?: object;
};

const Image = ({ imagePath, style }: ImageProps) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          fscs: file(relativePath: { eq: "fscs.png" }) {
            childImageSharp {
              fluid(maxWidth: 1300) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          hola: file(relativePath: { eq: "hola.png" }) {
            childImageSharp {
              fluid(maxWidth: 1300) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          letter: file(relativePath: { eq: "letter.png" }) {
            childImageSharp {
              fluid(maxWidth: 1300) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          logo: file(relativePath: { eq: "logo.png" }) {
            childImageSharp {
              fluid(maxWidth: 1300) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          pen: file(relativePath: { eq: "pen.png" }) {
            childImageSharp {
              fluid(maxWidth: 1300) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          tea: file(relativePath: { eq: "tea.png" }) {
            childImageSharp {
              fluid(maxWidth: 1300) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          vault: file(relativePath: { eq: "vault.png" }) {
            childImageSharp {
              fluid(maxWidth: 1300) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      `}
      render={(data) => (
        <Img
          fluid={data[imagePath].childImageSharp.fluid}
          imgStyle={style}
          alt={altTags[imagePath]}
        />
      )}
    />
  );
};

export default Image;
