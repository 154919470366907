import React from 'react';
import Layout from '../../components/layout';
import Image from '../../atoms/image';
import SEO from '../../components/seo';
import { titles, descriptions } from '../../const/metaData';
import {
  Wrapper,
  TextContainer,
  ImageContainer,
  ImageContent,
  Download,
} from '../../styles/commonStyles';
import styled from 'styled-components';
import { DOCUMENTS } from '../../const/documents';

const IconContainer = styled.div`
  height: 50px
  width: 50px
`;

const smallImageStyle = {
  marginTop: '20px',
  maxWidth: '105px',
  maxHeight: '55px',
};

const FSCS: React.FunctionComponent = () => {
  return (
    <Layout>
      <SEO title={titles.fscs} description={descriptions.fscs} />
      <Wrapper>
        <TextContainer>
          <h2>FSCS Policy</h2>
          <p>
            The money in your Bó account is protected up to a total of £85,000
            by the Financial Services Compensation Scheme (FSCS), the UK's
            deposit guarantee scheme (if you have accounts with both Bó and
            NatWest, the maximum you'd be able to claim across all accounts
            combined is £85,000).
          </p>
          <Download
            href={DOCUMENTS.FSCS.v1}
            target="_blank"
            rel="noopener noreferrer"
          >
            FSCS Information Sheet
          </Download>
          <IconContainer>
            <Image imagePath="fscs" style={smallImageStyle} />
          </IconContainer>
        </TextContainer>
        <ImageContainer>
          <ImageContent>
            <Image imagePath="vault" />
          </ImageContent>
        </ImageContainer>
      </Wrapper>
    </Layout>
  );
};

export default FSCS;
